import {$, maxHeight} from './helpers';
import './vendors/doubleTapToGo';
import Swiper from './vendors/swiper';

/**
 * Add class to slides
 */
const swiperSlideClass = () => {
    $('.swiper-wrapper > *').each(function () {
       $(this).addClass('swiper-slide');
    });
};

/**
 * Init home hero avatars slider
 *
 * @param selector
 */
const initHomeHeroAvatarsSlider = (selector = '.home-hero__avatars') => {
    if ($(selector).length) {
        $(selector).each(function () {
           const slider = new Swiper(this, {
               slidesPerView: 'auto',
               spaceBetween: 12,
               loop: true,
               autoplay: true,
               navigation: {
                   prevEl: '.home-hero__avatars__prev',
                   nextEl: '.home-hero__avatars__next'
               }
           });

           $(slider.params.navigation.prevEl).add(slider.params.navigation.nextEl).on('click', () => slider.autoplay.stop());
        });
    }
};


/**
 * Init home hero avatars slider fade
 *
 * @param selector
 */
const initHomeHeroAvatarsSliderFade = (selector = '.slider2-home') => {
    if ($(selector).length) {
        $(selector).each(function () {
           const slider = new Swiper(this, {
               effect: 'fade',
               slidesPerView: 1,
               loop: true,
               autoplay: true
           });
        });
    }
};


/**
 * Adjust size of :
 *
 * - Title
 * - Content
 * - Paddings
 *
 * @param el
 */
const homeTrainingSliderAdjustments = (el) => {
    // Titles same height
    let $titles = $(el).find('.home-trainings__slider__item__title'),
        $content = $(el).find('.home-trainings__slider__item__content'),
        titlesMaxHeight = maxHeight($titles);

    $titles.css('height', titlesMaxHeight);

    $(el)
        .find('a')
        .removeAttr('style')
        .css({
            'padding-top': titlesMaxHeight / 2,
            'padding-left': titlesMaxHeight / 2
        });

    setTimeout(function () {
        $content.each(function () {
            $(this).removeAttr('style');

            let contentWidth = $(this).outerWidth(),
                contentHeight = $(this).outerHeight();

            $(this)
                .css({
                    'width': contentWidth - (titlesMaxHeight / 2),
                    'height': contentHeight - ( (titlesMaxHeight / 2) * 2),
                    'margin-top': titlesMaxHeight / 2
                });
        });
    }, 300);
};

/**
 * Home trainings slider
 *
 * @param selector
 */
const initHomeTrainingsSlider = (selector = '.home-trainings__slider') => {
    if ($(selector).length) {
        $(selector).each(function () {
            const _this = this;
            const slider = new Swiper(_this, {
               slidesPerView: 1,
               loop: true,
               observer: true,
               autoplay: {
                   delay: 3000
               },
               navigation: {
                   prevEl: '.home-trainings__slider__prev',
                   nextEl: '.home-trainings__slider__next'
               },
               breakpoints: {
                   0: {
                       slidesPerView: 1,
                   },
                   768: {
                       slidesPerView: 2,
                       spaceBetween: 50
                   },
                   1150: {
                       slidesPerView: 3,
                       spaceBetween: 50
                   },
                   1500: {
                       slidesPerView: 3,
                       spaceBetween: 100
                   },
                   1800: {
                       slidesPerView: 4,
                       spaceBetween: 75
                   }
               },
               on: {
                   init: () => homeTrainingSliderAdjustments(_this),
                   resize: () => homeTrainingSliderAdjustments(_this)
               }
            });

            $(this).hover(
                function () {
                    this.swiper.autoplay.stop();
                },
                function () {
                    this.swiper.autoplay.start();
                }
            );

            $(_this).find('a').doubleTapToGo();
        });
    }
};

export const initSliders = () => {
    swiperSlideClass();
    initHomeHeroAvatarsSlider();
    initHomeTrainingsSlider();
    initHomeHeroAvatarsSliderFade();
};
import { $ } from '../helpers';
import { $anchorBlock } from '../constants';
import GumShoe from '../vendors/gumShoe';

/**
 * Init anchor block
 */
export const initAnchorBlock = (baseOffset = 20) => {
	if ($anchorBlock.length) {
		let $anchorBlockLinksMobile = $(document).find('.anchor-block__mobile');

		new GumShoe('.anchor-block__links a', {
				nested: true, reflow: true, offset: function () {
					let offset,
					    defaultOffset = baseOffset,
					    headerHeight  = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--header-height'));

					if (!headerHeight) headerHeight = 0;
					offset = defaultOffset + headerHeight;
					if ($anchorBlockLinksMobile.length && $anchorBlockLinksMobile.is(':visible')) offset += $anchorBlockLinksMobile.outerHeight();

					return offset;
				},
			},
		);
	}
};

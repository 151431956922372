/**
 * $ helper (instead of jQuery)
 */
export const $ = (el) => jQuery(el);

/**
 * Auto resize textarea (for a one line textarea)
 *
 * @param el
 */
const autosize = function(el) {
    setTimeout(function () {
        $(el).css('height', 'auto');
        $(el).css('height', parseInt(el.scrollHeight) + 'px');
    }, 0);
};

/**
 * Get max height of elements
 *
 * @param elems
 * @returns {number}
 */
export const maxHeight = (elems) => {
    return Math.max.apply(null, elems.map(function ()
    {
        return $(this)
            .removeAttr('style')
            .outerHeight();
    }).get());
};

/**
 * Activate the resize of textarea on enter or escape key
 */
export const textareaResize = function () {
    $('textarea').keydown(function (e) {
        let key = e.which || e.keyCode;
        if (key === 13 || key === 8) {
            autosize(this);
        }
    });
};

/**
 * Get youtube video code (to embed it)
 */
export const extractVideoIdFromYoutubeLink = youtubeLink => youtubeLink.split( 'v=' )[1].split( '&' )[0];

/**
 * Is a Touch device ?
 *
 * @returns {*}
 */
export const isTouchDevice = () => {
    let prefixes = ' -webkit- -moz- -o- -ms- '.split(' '),
        mq = query => window.matchMedia(query).matches;

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) return true;

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    let query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');

    return mq(query);
};

/**
 * File upload style + script to add image
 */
export const fileUpload = function (settings = {
    'file_upload_container': $('.file-upload'),
    'file_upload_text': $('.file-upload__text'),
    'file_upload_input': $('.file-upload__input'),
    'file_upload_default_input': $('.file-upload').find('input[type="file"]')
}) {
    let file_upload_container     = settings.file_upload_container,
        file_upload_text          = settings.file_upload_text,
        file_upload_input         = settings.file_upload_input,
        file_upload_default_input = settings.file_upload_default_input;

    // On click on the fake input, click on the file input
    file_upload_input.on('click', function () {
        file_upload_default_input.click();
    });

    // Text of the input
    file_upload_default_input.on('change', function () {
        let text = this.files[0].name; // Default text

        if (this.files.length > 1) {
            text = `${this.files.length} fichiers`;
        }

        file_upload_text.html(text);
    });

    // Reset file upload on mail sent
    $('.wpcf7').each(function () {
        $(this).on('wpcf7mailsent', function (event) {
            $(this).find(file_upload_input).val('');
            $(this).find(file_upload_text).html('Aucun fichier choisi');
        });
    });
};

/**
 * Back to top
 */
export const backToTop = () => {
    let $backToTop = $('.back-to-top');

    $backToTop.on('click', function () {
        $('html, body').stop().animate({scrollTop: 0}, 500);
    });

    $(window).on('load scroll', function () {
        if ($backToTop.length) {
            let scrollTop = $(window).scrollTop();

            (scrollTop > 500) ? $backToTop.addClass('is-active') : $backToTop.removeClass('is-active');
        }
    });
};
import { $ } from './helpers';
import Rellax from './vendors/rellax';

const parallaxs = [

    {
        selector: '.home-trainings__header__parallax',
        options: {
            speed: 2.2,
            horizontalParallax: true
        }
    },
    {
        selector: '.home-i-am__parallax',
        options: {
            speed: -2.2,
            horizontalParallax: true
        }
    },
    {
        selector: '.section--parallax__parallax',
        options: {
            speed: 3,
            horizontalParallax: true
        }
    },
    {
        selector: '.internal-page__header__parallax',
        options: {
            speed: 2.2,
            horizontalParallax: true
        }
    }

];

/**
 * Initialize all parallaxs
 */
export default function initParallaxs() {

    parallaxs.map(parallax => {
        let $selector = $(document).find(parallax.selector);
        if ($selector.length) {
            $selector.each(function () {
                new Rellax(this, parallax.options);
            });
        }
    });

    if ($(document).find('.rellax').length) {
        new Rellax('.rellax');
    }

};
import { $ } from './helpers';

/**
 * Set up a max width for retina images
 */
export const retinaImages = () => {
    $('.home-hero__images img, .retina-images img').each(function () {
        $(this).removeAttr('style').css({
            'max-width': `${this.naturalWidth / 2}px`,
            'width': '100%'
        });
    });
};

/**
 * Animation of texts in the homepage
 */
export const homeHeroLeadTextAnimation = () => {
    let container = '.home-hero__text__lead';

    if ($(container).length) {
        let texts = $(container).find('.wpb_text_column:eq(1) > div > *'),
            textsLength = texts.length - 1,
            position = $(container).find('.animate-in').length ? $(container).find('.animate-in').index() : texts.eq(0),
            position2 = 0;

        if (position < textsLength) position2 = position + 1;

        texts.eq(position)
            .removeClass('animate-in')
            .addClass('animate-out');

        texts.eq(position2).addClass('animate-in');

        setTimeout(
            () => texts.eq(position).removeClass('animate-out'),
            1000
        );

        setTimeout(
            () => homeHeroLeadTextAnimation(),
            1500
        );
    }
};
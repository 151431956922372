import { $, backToTop, fileUpload, maxHeight, extractVideoIdFromYoutubeLink, isTouchDevice } from './helpers';
import { $body, $cover, $pageWrapper, $responsiveHeader, loadingSVG, pentagoneSVG, triangleSVG } from './constants';
import { initSliders } from './sliders';
import { homeHeroLeadTextAnimation } from './functions';
import initParallaxs from './parallaxs';
import './vendors/jquery.hoverintent';
import { initAnchorBlock } from './components/anchorBlock';
import DOMAnimations from './components/DOMAnimations';
import Modal from './components/Modal';
import Cookie from './components/Cookie';

let onReady,
    initFunctions,
    onLoad,
    onResize,
    onScroll,
    timer = null;

/**
 * Init functions, after onReady
 */
initFunctions = function () {
	initSliders();
	homeHeroLeadTextAnimation();
	initParallaxs();
	backToTop();
	fileUpload();
	initAnchorBlock(25);
};

/**
 * When document is ready
 */
onReady = function () {

	const $sellsyButton = $('#sellsy_btn_contact');
	if ($sellsyButton) {
		const $civilitiesInput = document.querySelector('#contact_form_contact_civility');
		if ($civilitiesInput) {
			const $womanOption = $civilitiesInput.querySelector(['option[value="woman"]']);
			if ($womanOption) {
				$womanOption.selected = true;
			}
		}
		$sellsyButton.parent('form').on('submit', function () {
			const $email = $('#contact_form_contact_email');
			if (!$email || !$email.val() || $email.val().length === 0) return;
			const email = $email.val();

			Cookie.create('sellsy_contact_email', email, 0.1);
		});
	}

	// Remove VC tabs animations
	if (typeof jQuery.fn.vcAccordion !== 'undefined') jQuery.fn.vcAccordion.Constructor.prototype.isAnimated = () => 0;

	$('.header__menu li.menu-item-has-children, .image-block').doubleTapToGo();

	$('.header__bottom__responsive').on('click', function () {
		$(this).find('.hamburger').toggleClass('is-active');
		$body.toggleClass('header--is-active');
		$responsiveHeader.stop().slideToggle();
	});

	$('.overlay-header').on('click', function () {
		$body.removeClass('header--is-active');
		$responsiveHeader.stop().slideUp();
		$('.header__bottom__responsive .hamburger').removeClass('is-active');
	});

	$responsiveHeader.find('li.menu-item-has-children > a').on('click', function (e) {
		let $this = $(this);

		e.preventDefault();
		$this.parent().toggleClass('is-active');
		$this.parent().find('.responsive-header__menu__submenu-toggler').first().toggleClass('is-active');
		$this.parent().find('.sub-menu').first().slideToggle();
	});

	$responsiveHeader.find('li.menu-item-has-children > a > span:first-child').on('click', e => e.stopPropagation());

	$('.home-i-am__tab__images')
	.find('.wpb_single_image')
	.eq($('.home-i-am__tab .vc_active').index())
	.addClass('is-active');

	$('.home-i-am__tab .vc_tta-tab').on('click', function () {
		$('.home-i-am__tab__images')
		.find('.is-active')
		.removeClass('is-active');

		$('.home-i-am__tab__images')
		.find('.wpb_single_image')
		.eq($('.home-i-am__tab .vc_tta-tab').index(this))
		.addClass('is-active');
	});

	$('.header__menu > div > ul > li.menu-item-has-children:not(.isMegaMenu), .header__menu > div > ul > li.menu-item-has-children:not(.isMegaMenu) li.menu-item-has-children')
	.hoverIntent({
		over: function () {
			$(this).find('.sub-menu').first().stop().fadeIn(300);
		},
		out: function () {
			$(this).find('.sub-menu').first().stop().fadeOut(300);
		},
		timeout: 100
	});

	$('.header__menu li.menu-item-has-children.isMegaMenu').hoverIntent({
		over: function () {
			$(this).find('.sub-menu').first().stop().fadeIn(300);
		},
		out: function () {
			$(this).find('.sub-menu').first().stop().fadeOut(300);
		},
		timeout: 100
	});

	// Wrap sub menu in a div to handle margins
	const $megaMenusSubMenu = document.querySelectorAll('.header__menu .isMegaMenu > ul.sub-menu');
	$megaMenusSubMenu.forEach($megaMenuSubMenu =>
		$megaMenuSubMenu.innerHTML = `
                <div class="megaMenu__container">
                    ${$megaMenuSubMenu.innerHTML}
                </div>
            `);

	// Team profile header should be the maximum height
	$('.team-container').each(function () {
		let maxHeightHeader = maxHeight($(this).find('.team-container__profile__header'));

		$(this).find('.team-container__profile__header').css('height', maxHeightHeader);
	});

	// Section parallax
	let $sectionParallax = $('.section--parallax');

	if ($sectionParallax.length) {
		$sectionParallax.each(function () {
			let textParallax = $(this).attr('id');

			if (!!textParallax) {
				textParallax = textParallax.replace('-', ' ');

				$(this).append(`<div class="section--parallax__parallax"><p>${textParallax}</p></div>`);
			}
		});
	}

	// Geometrics professionnal packs
	let $geometricsProfesionnalPacks = $('.professional-packs--geometrics');

	if ($geometricsProfesionnalPacks.length) {
		$geometricsProfesionnalPacks.each(function () {
			$(this).append(`
                <div class="geometric geometric--rectangle" />
                <div class="geometric geometric--1 geometric--pentagone">${pentagoneSVG}</div>
                <div class="geometric geometric--2 geometric--triangle">${triangleSVG}</div>
                <div class="geometric geometric--3 geometric--rectangle"></div>
            `);

			$(this).find('.geometric').each(function () {
				let randomSpeed = Math.floor(Math.random() * (
				                             2 - (
					                             -2
				                             ) + 1
				) + -2);
				$(this).addClass('rellax').attr('data-rellax-speed', randomSpeed);
			});
		});
	}

	// Geometrics presses
	let $geometricsPresses = $('.presses');

	if ($geometricsPresses.length) {
		$geometricsPresses.each(function () {

			$(this).append(`
                <div class="geometric geometric--1 geometric--rectangle" />
                <div class="geometric geometric--2 geometric--triangle">${triangleSVG}</div>
                <div class="geometric geometric--3 geometric--pentagone">${pentagoneSVG}</div>
                <div class="geometric geometric--4 geometric--triangle">${triangleSVG}</div>
                <div class="geometric geometric--5 geometric--pentagone">${pentagoneSVG}</div>
                <div class="geometric geometric--6 geometric--rectangle" />
            `);

			$(this).find('.geometric').each(function () {
				let randomSpeed = Math.floor(Math.random() * (
				                             2 - (
					                             -2
				                             ) + 1
				) + -2);
				$(this).wrap('<div class="geometric-wrapper rellax" data-rellax-speed="' + randomSpeed + '" />');
			});

			$pageWrapper.css('overflow', 'hidden');
		});
	}

	// Presses
	$('.press__thumbnail, .press__title, .press__date').on('click', function (e) {
		let type = $(this).closest('.press').attr('data-press-type');

		// Open in a new tab if height is <= 600px OR width <= 1024 OR If it's a touch device
		if (type === 'press' && (
			window.matchMedia('(max-height: 600px), (max-width: 1025px').matches || isTouchDevice()
		))
			return;
		else
			e.preventDefault();

		let link                     = $(this).closest('.press').attr('data-press-link'),
		    $pressViewWrapper        = $(`
                <div class="press-view-wrapper press-view-wrapper--${type}">
                    <div class="press-view-wrapper__close" />
                </div>
            `),
		    $pressViewContentWrapper = $('<div class="press-view-wrapper__content" />');

		if ($(document).find('.press-view-wrapper').length) $(document).find('.press-view-wrapper').remove();

		if (type === 'press') {
			let $pdfViewer = $(`
                <object data="${link}" width="100%" height="100%" type="application/pdf">
                    <embed src="${link}" width="100%" height="100%" type="application/pdf">
                </object>
                <div class="press-view-wrapper__loading">${loadingSVG}</div>
            `);

			$body.append($pressViewWrapper.append($pressViewContentWrapper.append($pdfViewer)));
		} else if (type === 'video') {
			let $videoViewer = $(`
                <div class="video-wrapper">
                    <div class="video-container">
                        <iframe src="https://www.youtube.com/embed/${extractVideoIdFromYoutubeLink(link)}" width="100%" height="100%" frameborder="0"></iframe>
                        <div class="press-view-wrapper__loading">${loadingSVG}</div>
                    </div>
                </div>
            `);

			$body.append($pressViewWrapper.append($pressViewContentWrapper.append($videoViewer)));
		}

		$body.addClass('press-view--is-active');

		// Video iframe loading
		$(document).find('.press-view-wrapper--video iframe').css('opacity', 0);
		$(document).find('.press-view-wrapper--video iframe').on('load', function () {
			$(document).find('.press-view-wrapper__loading').remove();
			$(this).css('opacity', 1);
		});

		// Press loading (after 750ms, show the PDF)
		$(document).find('.press-view-wrapper--press object').css('opacity', 0);
		setTimeout(function () {
			$(document).find('.press-view-wrapper__loading').remove();
			$(document).find('.press-view-wrapper--press object').css('opacity', 1);
		}, 750);

	});

	// Close press viewer
	const closePressViewWrapper = (el) => {
		if ($(el).length) {
			$body.removeClass('press-view--is-active');
			setTimeout(() => $(el).remove(), 350);
		}
	};

	// Close press viewer on ESC press
	$(document).keyup(function (e) {
		if (e.keyCode === 27) closePressViewWrapper($(document).find('.press-view-wrapper'));
	});

	// Close press viewer on click on the background
	$(document).on('click', '.press-view-wrapper', () => closePressViewWrapper($(document).find('.press-view-wrapper')));
	$(document).on('click', '.press-view-wrapper__content', e => e.stopPropagation());

	// Close press viewer on click on the close button
	$(document).find('.press-view-wrapper__close').on('click', function () {
		closePressViewWrapper($(document).find('.press-view-wrapper'));
	});

	$(window).on('load scroll', function () {
		let scrollTop = $(this).scrollTop(),
		    footerTop = $('.footer').offset().top - $('.footer').outerHeight();

		if (scrollTop >= footerTop) {
			$('.datadock').addClass('is-out');
		} else {
			$('.datadock').removeClass('is-out');
		}
	});


	/***** Ancre Mobile *******/
	if ($('.anchor-block__mobile').length) {
		let sElementAncres     = $('.anchor-block__mobile'),
		    iPosAncres         = sElementAncres.offset().top,
		    iPostBottomElement = $('footer.footer').offset().top;

		$(document).on('scroll', function (event) {
			let iScrollPos = $(document).scrollTop();

			if ((
				    iPosAncres <= iScrollPos
			    ) && (
				    iPostBottomElement > iScrollPos
			    )) {
				sElementAncres.addClass('is_sticky');
			} else {
				sElementAncres.removeClass('is_sticky');
			}

		});
	}

	const $readMore = $('.read-more');

	$(window).on('load resize', function () {
		if ($readMore.length && window.matchMedia('(max-width: 480px)').matches) {
			$readMore.each(function () {
				if (!$(this).hasClass('read-more--is-active')) {
					$readMore.wrapInner('<div class="read-more__text-container" />');

					$readMore
					.find('.read-more__text-container')
					.css('height', 100);

					$readMore
					.addClass('read-more--is-active')
					.append('<p class="read-more__text">Lire plus ...</p>');
				}
			});
		} else {
			$readMore.each(function () {
				if ($(this).hasClass('read-more--is-active')) {
					$(this).removeClass('read-more--is-active');
					$(this).find('.read-more__text-container').contents().unwrap();
					$(this).find('.read-more__text').remove();
				}
			});
		}
	});
	$(document).on('click', '.read-more', function () {
		if (!$(this).hasClass('read-more--toggled')) {
			$(this).find('.read-more__text-container').css('height', 'auto');
			$(this).addClass('read-more--toggled');
			$(this).find('.read-more__text').html('Lire moins ...');
		} else {
			$(this).find('.read-more__text-container').css('height', 100);
			$(this).removeClass('read-more--toggled');
			$(this).find('.read-more__text').html('Lire plus ...');
		}
	});


	// Popup Fermeture Cabinet
	function _hasCookies(cookieName) {
		if (document.cookie.indexOf(cookieName + '=true') > -1) {
			return true;
		} else if (document.cookie.indexOf(cookieName + '=false') > -1) {
			return false;
		}
		return null;
	}

	function _setCookies(name, value, minutes) {
		var d = new Date();
		d.setTime(d.getTime() + (
			minutes * 60 * 1000
		));
		var expires = 'expires=' + d.toUTCString();
		document.cookie = name + '=' + value + ';' + expires + ';path=/';
	}

	if (_hasCookies('PopupCabinetClosed') == null) {
		setTimeout(function () {
			$('.popup_closeinfos, .popup_closeinfos__overlay').fadeIn(400);
		}, 1000);
	}

	$(document).on('click', '.popup_closeinfos__close', function (event) {
		$('.popup_closeinfos, .popup_closeinfos__overlay').fadeOut('400');
		_setCookies('PopupCabinetClosed', true, 60);
		event.preventDefault();
	});

	const $homeHeroExperts = $('.homeHero > div:nth-child(3)');

	if ($homeHeroExperts.length) {
		$homeHeroExperts.wrapInner('<div class="homeHero__experts__wrapper" />');

		$(window).on('load resize', function () {
			if ($('.homeHero__experts__wrapper').length === 0) $homeHeroExperts.wrapInner('<div class="homeHero__experts__wrapper" />');
			if (window.innerWidth < 1024) {
				$homeHeroExperts.removeAttr('style');
				return;
			}

			$homeHeroExperts
			.removeAttr('style')
			.height($('.homeHero__experts__wrapper')[0].getBoundingClientRect().height);
		});
		document.body.classList.add('fixedExperts');

		const $observeSection = $homeHeroExperts[0];
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 1
		};

		let previousY     = 0,
		    previousRatio = 0;

		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (window.innerWidth < 1024) return;
				const currentY = entry.boundingClientRect.y;
				const currentRatio = entry.intersectionRatio;

				if (entry.isIntersecting) {
					document.body.classList.add('fixedExperts');
				} else if (currentY > previousY) {
					document.body.classList.remove('fixedExperts');
				}

				previousY = currentY;
				previousRatio = currentRatio;
			});
		}, options);

		observer.observe($observeSection);
	}

	const $widgetFormation = $('.widgetFormation');

	$(window).on('load scroll', function () {
		const scrollTop = $(window).scrollTop();
		if (scrollTop > 950) {
			$widgetFormation.addClass('is-hidden');
		} else {
			$widgetFormation.removeClass('is-hidden');
		}
	});

	// Contact page
	const $contactSelect = document.querySelector('#contactSubjects__select');

	if ($contactSelect) {
		const activeFormClass = 'isActive';

		/**
		 * Active form in the contact select
		 * And store the index of the form
		 *
		 * @param $contactForm
		 * @param $activeForm
		 */
		const activeFormInContactSelect = ($contactForm, $activeForm) => {
			if ($activeForm && $contactForm) {
				DOMAnimations.slideUp($activeForm).then(() => {
					$activeForm.classList.remove(activeFormClass);
					DOMAnimations.slideDown($contactForm).then(() => $contactForm.classList.add(activeFormClass));
				});
			} else if ($contactForm) {
				DOMAnimations.slideDown($contactForm).then(() => $contactForm.classList.add(activeFormClass));
			}

			localStorage.setItem('activeFormIndex', $contactForm.getAttribute('data-form-index'));
		};

		const localStorageActiveFormIndex = localStorage.getItem('activeFormIndex');
		if (localStorageActiveFormIndex && document.querySelector('.internal-page').classList.contains('contactPage--havePostedValues')) {
			activeFormInContactSelect(
				document.querySelector(`.contactSubjects__forms > [data-form-index="${localStorageActiveFormIndex}"]`)
			);
			$contactSelect.value = localStorageActiveFormIndex;
		}

		$contactSelect.addEventListener('change', function () {
			const $activeForm = document.querySelector(`.contactSubjects__forms > .${activeFormClass}`);
			const $contactForm = document.querySelector(`.contactSubjects__forms > [data-form-index="${this.value}"]`);
			activeFormInContactSelect($contactForm, $activeForm);
		});
	}

	/**
	 * Set header height in a css variable
	 */
	function setHeaderHeight() {
		const {height} = document.querySelector('.header').getBoundingClientRect();
		document.documentElement.style.setProperty('--header-height', `${height}px`);
	}

	$(window).on('load resize', setHeaderHeight);


};

/**
 * When window is loaded
 */
onLoad = function () {
	function forceInputUppercase(e) {
		var start = e.target.selectionStart;
		var end = e.target.selectionEnd;
		e.target.value = e.target.value.toUpperCase();
		e.target.setSelectionRange(start, end);
	}

	const $contactFormContactLastName = document.getElementById('contact_form_contact_lastname');
	if ($contactFormContactLastName) {
		$contactFormContactLastName.addEventListener('keyup', forceInputUppercase, false);
	}

	// Home hero max width images
	$('.home-hero__images img, .retina-images img').each(function () {
		$(this).removeAttr('style').css({
			'max-width': `${this.naturalWidth / 2}px`,
			'width': '100%'
		});
	});

	let removeLoader = null;
	$('#cover').remove();

	removeLoader = setInterval(() => {
		if ($('#cover') && $('#cover').length)
			$('#cover').remove();
		else
			clearInterval(removeLoader);
	}, 1000);
};

/**
 * When window is resized
 */
onResize = function () {
	// Home hero max width images
	$('.home-hero__images img, .retina-images img').each(function () {
		$(this).removeAttr('style').css({
			'max-width': `${this.naturalWidth / 2}px`,
			'width': '100%'
		});
	});

	// Team profile header should be the maximum height
	$('.team-container').each(function () {
		let maxHeightHeader = maxHeight($(this).find('.team-container__profile__header'));

		$(this).find('.team-container__profile__header').css('height', maxHeightHeader);
	});

	/* Remove transitions on window resize */
	if (timer) {
		clearTimeout(timer);
		timer = null;
	} else {
		$body.addClass('stop-transitions');
	}

	timer = setTimeout(() => {
		$body.removeClass('stop-transitions');
		timer = null;
	}, 100);
};

/**
 * When window is scrolled
 */
onScroll = function () {

};

const App = {

	init: function () {

		$(document).on('ready', onReady);
		$(document).on('ready', initFunctions);
		$(window).on('load', onLoad);
		$(window).on('resize', onResize);
		$(window).on('scroll', onScroll);

	}

};

customElements.define('modal-popup', Modal);

App.init();
